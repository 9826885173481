.HeaderWrap {
    display: inline-block;
    width: 100%;
}
.HeaderWrap button{
    margin: 0 0;
    padding: 0 0;
    width: 15em;
    background-color: transparent;
}

.horizontalLogo {
    width: 13em;
    float: left;
}

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .horizontalLogo {
        width: 10em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .HeaderWrap button{
        margin: 0 0;
        padding: 0 0;
        width: 10em;
    }
    .horizontalLogo {
        width: 7.5em;
    }
}