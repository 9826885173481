@import url('https://fonts.googleapis.com/css?family=Satisfy|Barlow');

.orderPageWrapper {
    padding-top: 3em;
    padding-bottom: 10em;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
    text-align: center;
    /*background-color: pink;*/
}

.orderPageWrapper h3 {
    font-size: 3em;
    font-family: "Barlow";
}

.orderPageWrapper h4 {
    font-size: 1.5em;
    padding-left: 1em;
    text-align: left;
    font-family: "Barlow";
}

.orderPageWrapper p {
    font-size: 1em;
    text-align: left;
    padding-left: 3em;
    font-family: "Barlow";
}

.orderoption {
    margin-left: 2em;
    background-color: #ffac81;
    background-image: linear-gradient(315deg, #ffac81 0%, #ff928b 74%); 
    padding-bottom: 1em;   
}
.orderoption p {
    /* padding: 0.5em; */
    padding-left: 3em;
}

.noteoption {
    margin-left: 2em;
    background-color: #eec0c6;
    background-image: linear-gradient(315deg, #eec0c6 0%, #e58c8a 74%);
    padding: 0.5em;
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .orderPageWrapper {
        padding-top: 1.5em;
        padding-bottom: 10em;
        margin: 0 5%;
        width: 90%;
        float: left;
        text-align: center;
    }
    .orderPageWrapper h3 {
        font-size: 1.5em;
        font-family: "Barlow";
    }
    .orderPageWrapper p {
        font-size: 0.9em;
        text-align: left;
        font-family: "Barlow";
    }
    
}