@import url('https://fonts.googleapis.com/css?family=Barlow');

.aboutPageWrapper {
    padding-top: 3em;
    padding-bottom: 7em;
    width: 100%;
    /*float: left;*/
    text-align: center;
    /*background-color: pink;*/
}

.aboutTextWrapper {
    width: 65%;
    margin: 0 auto;
}
.aboutPageWrapper p {
    font-size: 1.5em;
}

.abouttext {
    font-family: "Barlow";
    color: #484848;
}

.aboutPageWrapper p {
    font-size: 1.3em;
}

.aboutHeaders {
    font-family: "Satisfy";
    font-size: 2em;
    color: #846C6C;
}

/* For Mobile */
@media screen and (max-width: 540px) {
    .aboutPageWrapper {
        padding-top: 0.5em;
        padding-bottom: 3em;
        margin: 0%;
        width: 100%;
        float: left;
        text-align: center;
        /*background-color: pink;*/
    }

    .aboutHeaders {
        font-family: "Satisfy";
        font-size: 1.5em;
        color: #846C6C;
    }

    .aboutPageWrapper p {
        font-size: 1em;
    }
}
