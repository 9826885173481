@import url('https://fonts.googleapis.com/css?family=Itim');

.aboutsitewrapper {
    padding-top: 3em;
    padding-bottom: 10em;
    margin: auto 5%;
    width: 80%;
    float: left;
    text-align: left;
    /*background-color: pink;*/
}

.aboutsitewrapper * {
    font-family: 'Itim'
}