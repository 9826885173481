@import url('https://fonts.googleapis.com/css?family=Satisfy|Barlow');

.navBarWrapper {
    width: 80%;
    /*clear: left;*/
    float: right;
}

.links {
    float: left;
    font-size: 1.2em;
    text-decoration: none;
    color: #484848;
    font-family: "Barlow";
}

.link1 {
    margin-left: 9%;
}

.links:hover {
    color:  #846C6C;
    font-size: 1.1em;
}
  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .navBarWrapper {
        width: 70%;
        /*background-color: green;*/
    }

    .links {
        font-size: 1em;
    }

    .link1 {
        margin-left: 1em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .navBarWrapper {
        padding-top: 0.5em;
        width: 100%;
    }
    .links {
        margin-left: 1em;
        font-size: 0.9em;
    }
    .links:hover {
        font-size: 1em;
    }
}
