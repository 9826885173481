@import url('https://fonts.googleapis.com/css?family=Satisfy|Barlow');

.homePageWrapper {
    width: 75%;
    padding-top: 3em;
    padding-bottom: 9em;
    margin-left: 12%;
    margin-right: auto;
    float: left;
    text-align: center;
    /*background-color: pink;*/
}

#bannerphoto1 {
    width: 100%;
}

#bannermessage1 {
    background-color: #990000;
    padding: 0.3em;
    margin: 0.5em;
}

#bannermessage1 > h3 {
    color: white;
    font-size: 3em;
}

#bannermessage1 > h5 {
    color: white;
    font-size: 2em;
}

#bannermessage1 > h5 > a {
    color: white;
    font-size: 0.8em;
}

#bannerphoto2 {
    width: 100%;
}

#gardencaption, #collagecaption {
    text-align: right;
    font-family: Satisfy;
    font-size: 2em;
    color: #484848;
}

#lipbalmcaption {
    text-align: left;
    font-family: Satisfy;
    font-size: 2em;
    color: #484848;
}

#collagehomepage {
  width: 100%;
  margin-top: 2em;
}

#firstcollageportrait {
    float: left;
    width: 45%;
    padding-left: 3em;
}

#secondcollageportrait {
    float: left;
    width: 50.5%;
    padding-left: 4em;
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .homePageWrapper {
        padding-top: 0.5em;
        padding-bottom: 7em;
        margin: 0% 3%;
        width: 90%;
        text-align: center;
    }

    #bannerphoto1 {
        margin-top: 1em;
    }

    #gardencaption {
        font-size: 1em;
    }
    
    #collagecaption {
        font-size: 1em;
    }

    #lipbalmcaption {
        font-size: 1em;
    }

    #collagehomepage {
        margin-top: 1em;
        width: 100%;
    }

    #firstcollageportrait {
        float: left;
        width: 45%;
        padding-left: 0em;
        margin-left: 0.5em;
    }
    
    #secondcollageportrait {
        float: left;
        width: 51.5%;
        padding-left: 0em;
    }
}