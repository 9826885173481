@import url('https://fonts.googleapis.com/css?family=Satisfy|Barlow');

.productsPageWrapper {
    padding-top: 2em;
    padding-bottom: 10em;
    float: left;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    /*background-color: pink;*/
}

.productPageHeader {
    /*background-color: rgba(162, 119, 123, 0.5);*/
    height: 10em;
    width: 100%;
    margin-bottom: 1em;
}

.productlinkbuttons {
    background-color: rgba(255, 180, 71, 0.2);
    color: black;
    font-size: 1em;
    font-family: Barlow;
}

.productLink {
    font-family: Barlow;
    font-size: 1.5em;
}

.productLinks {
    padding-bottom: 1em;
    text-align: left;
}

.productPageHeader h1{
    font-family: "Satisfy";
    font-size: 3em;
    padding-top: 0.6em;
    color: #846C6C;
}


/*
.productsCardWrapper {
    width: 85%;
    margin: 0 auto;
}*/

/*
.oddProductCard, .evenProductCard {
    padding: 0em 1em;
}*/

/*
.oddProductCard {
    overflow: auto;
    clear: left;
    clear: right;
    margin-top: 1.5em;
}

.oddProductCard .oddProductText {
    float: left;
    text-align: left;
}

.oddProductCard .oddImageCont {
    background-color: blue;
    width: 2em;
    height: 17em;
    float: right;
}

.oddProductCard img {
    float: right;
}

.evenProductCard {
    overflow: auto;
    clear: left;
    clear: right;
    margin-top: 1.5em;
}

.evenProductCard .evenProductText {
    float: right;
    text-align: right;
}

.evenProductCard .evenImageCont {
    background-color: red;
    width: 2em;
    height: 17em;
    float: left;
}

.evenProductCard img {
    float: left;
}

.productsPageWrapper img {
    width: 55%;
    height: 17em;
}*/

  /* For Mobile */
@media screen and (max-width: 540px) {
    .productsPageWrapper {
        padding-top: 0.5em;
        width: 100%;
        margin-left: 0%;
    }

    .productlinkbuttons {
        font-size: 0.7em;
    }

    .productPageHeader {
        height: 5em;
        width: 100%;
        margin-bottom: 2em;
    }

    .productPageHeader h1{
        font-family: "Satisfy";
        font-size: 1.5em;
        padding-top: 1em;
    }

    .candlegridcolumn {
        float: left;
        width: 50%;
        padding: 3px;
    }
    
    .contwrapper {
        float: left;
        width: 50%;
        padding: 5px;
    }
    
    #jarcontwrapper {
        padding-left: 1em;
    }

    #aboutcandleproducts p {
        font-family: "Satisfy";
        font-size: 1em;
    }
    
    #aboutcandleproducts h5 {

    }
    
    #aboutcandleproducts li {
        font-size: 0.8em;
    }

    /*
    .oddProductCard .oddImageCont {
        background-color: blue;
        width: 1em;
        height: 9em;
    }
    
    .evenProductCard .evenImageCont {
        background-color: red;
        width: 1em;
        height: 9em;
    }

    .productsPageWrapper img {
        width: 55%;
        height: 9em;
    }*/
}