/* GLOBAL STYLES ONLY, ALL OTHER CSS GOES WITH ITS COMPONENTS */
* {
  font-family: 'Pompiere';
  box-sizing: border-box;
}

.App {
  /*background-color: brown;*/
}

