@import url('https://fonts.googleapis.com/css?family=Barlow');

.footerWrapper {
    clear: left;
    border-top: 3px solid grey;
    margin: 0 auto;
    width: 100%;
}

.contentFooter {
    display: inline-block;
}

.footerLine {
    padding-left: 1em;
    padding-top: 0.5em;
}

.contentFooter a {
    padding-left: 0.5em;
    font-size: 0.8em;
    font-family: 'Barlow';
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .footerLine {
        padding-left: 1em;
        padding-top: 0.2em;
    }
    .contentFooter a {
        font-size: 0.85em;
    }
}