@import url('https://fonts.googleapis.com/css?family=Satisfy|Barlow');

.contact-form, label {
    margin-top: 0.5em;
    font-size: 1em;
    display: inline-block;
    font-family: "Barlow";
}
.contact-form, h1 {
    font-size: 1em;
    font-family: "Barlow";
    display: inline-block;
}

.contact-form, input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
}

.contact-form, select {
    width: 50%;
    padding: 12px 0px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;

}

option {
    font-size: 3em;
}

.contact-form, button {
    width: 100%;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#candleOptionForm {
    /* box-sizing: border-box; */
    width: 100%;
    display: inline-block;
}

#candleOptionForm select {
    width: 75%;
    height: 3em;
    float: left;
}

#candleOptionForm .qty {
    width: 15%;
    height: 3em;
    float: left;
    margin-left: 1em;
}

.orderBox {
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #846C6C;
    background-color: #D8CBCB;
    min-height: 5em;
}

.addButton {
    width: 2em;
    height: 2em;
}

.disabledButton {
    background-color: grey;
}

.abledButton {
    background-color: #4CAF50;
}

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .contact-form, label {
        font-size: 1.5em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .contact-form, label {
        font-size: 1em;
        display: inline-block;
    }
    .contact-form, h1 {
        font-size: 1em;
        display: inline-block;
    }
    .contact-form, label {
        font-size: 1em;
    }
    #candleOptionForm select {
        width: 60%;
        float: left;
        height: 2.9em;
    }
    #candleOptionForm .qty {
        width: 27%;
        height: 2.9em;
        float: left;
        margin-left: 0.2em;
    }
    .orderBox {
        box-sizing: border-box;
        border-radius: 10px;
        border: 2px solid #846C6C;
        background-color: #D8CBCB;
        min-height: 3em;
    }    
    .addButton {
        width: 1.7em;
        height: 1.7em;
        margin-left: auto;
    }

}