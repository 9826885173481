@import url('https://fonts.googleapis.com/css?family=Fredoka+One');

.pictureMarketingWrapper {
  margin-top: 2em;
  /*width: 90%;*/
  justify-content: center;
  height: 25em;
  background-color: #fdf496;
  /*background-image: url('../../pictures/assets/amaZen_med_copy.png')*/
}
.typewriter {
    padding: 5.5em 1em;
    justify-content: center;
    width: 100%;
}
.typewriter h1 {
    font-family: 'Fredoka One';
    width: 100%;
    font-size: 1.9em;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: .25em;
    text-align: center;
}
.typewriter h1:nth-child(1){
    border-right: .15em solid orange;
    -webkit-animation: type 3s steps(40, end);
    animation: type 3s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .typewriter h1:nth-child(2){
    border-right: .15em solid orange;
    opacity: 0;
    -webkit-animation: type2 3s steps(40, end);
    animation: type2 3s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .typewriter h1:nth-child(3){
    border-right: .15em solid orange;
    opacity: 0;
    -webkit-animation: type3 3s steps(40, end), blink .5s step-end infinite alternate;
    animation: type3 3s steps(40, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
  }
  
  
  /* DEMO-SPECIFIC STYLES */

  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .pictureMarketingWrapper {
      height: 20em;
    }
    .typewriter {
        padding: 5em 1em;
    }
    .typewriter h1 {
        font-size: 1.2em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .pictureMarketingWrapper {
        margin-top: 2em;
        padding-top: 3em;
        width: 100%;
        height: 20em;
    }
    .typewriter {
        padding: 4em 0.5em;
    }
    .typewriter h1 {
        font-size: 0.75em;
    }
}
