@import url('https://fonts.googleapis.com/css?family=Barlow');

.CandleDisplayWrapper {
    width: 100%;
    clear: left;
}

#candleimagegrid {
    overflow: auto;
}

.candlegridcolumn {
    float: left;
    width: 50%;
    padding: 10px;
}

.candlegridcolumn img {
    margin-top: 12px;
    width: 100%;
}

#aboutcandleproducts p {
    font-family: "Satisfy";
    font-size: 1.5em;
    color: #484848;
}

.ProductType {
    font-family: "Satisfy";
    font-size: 2em;
}

#aboutcandleproducts h5 {
    font-family: "Satisfy";
    font-size: 1.1em;
    text-align: left;
    color: #484848;
}

#aboutcandleproducts li {
    font-family: "Barlow";
    text-align: left;
    color: #585858;
}

.contwrapper {
    float: left;
    width: 50%;
    padding: 5px;
}

#jarcontwrapper {
    padding-left: 5em;
}

#aboutcandleproducts {
    overflow: auto;
    width: 100%;
}

.candleButtons {
    width: 100%;
    margin-top: 2em;
}

.candleButtons p {
    font-size: 1em;
    margin: 0 auto;
    font-family: 'Barlow';
    color: #484848;
}

.candleButtons button {
    width: 10em;
    margin: 2px;
    background-color: transparent;
}

.candleButton {
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

#candletitle {
    font-size: 2em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

.candleimages {
    width: 25%;
    float: left;
}
#selectedJarCandleImage, #selectedGlassCandleImage {
    width: 100%;
    margin: 0.5em;
}
#selectedCandleDesc {
    margin-top: 2em;
    width: 70%;
    float: right;
}

.productlabel {
    text-align: left;
    font-size: 1em;
    width: 80%;
    font-family: Barlow;
    color: #585858;
}

.candledesc {
    font-size: 1.5em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .candleButtons button {
        width: 4.9em;
        margin: 1px;
    }

    .candleButtons {
        width: 100%;
        margin-top: 0.5em;
    }

    .candleButtons p {
        font-size: 0.7em;
    }

    .candleButton {
        width: 3em;
        height: 3em;
        border-radius: 50%;
    }
    #candletitle {
        font-size: 1em;
        text-align: left;
    }
    .candleimages {
        width: 30%;
    }
    #selectedJarCandleImage, #selectedGlassCandleImage {
        margin: 0.2em;
    }
    #selectedCandleDesc {
        margin-top: 0;
        width: 65%;
    }
    .productlabel {
        font-size: 0.7em;
        width: 80%;
    }
    .candledesc {
        font-size: 1em;
    }
    
}