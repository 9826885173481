@import url(https://fonts.googleapis.com/css?family=Barlow);
@import url(https://fonts.googleapis.com/css?family=Satisfy|Barlow);
@import url(https://fonts.googleapis.com/css?family=Satisfy|Barlow);
@import url(https://fonts.googleapis.com/css?family=Barlow);
@import url(https://fonts.googleapis.com/css?family=Barlow);
@import url(https://fonts.googleapis.com/css?family=Satisfy|Barlow);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One);
@import url(https://fonts.googleapis.com/css?family=Satisfy|Barlow);
@import url(https://fonts.googleapis.com/css?family=Satisfy|Barlow);
@import url(https://fonts.googleapis.com/css?family=Barlow);
@import url(https://fonts.googleapis.com/css?family=Itim);
.aboutPageWrapper {
    padding-top: 3em;
    padding-bottom: 7em;
    width: 100%;
    /*float: left;*/
    text-align: center;
    /*background-color: pink;*/
}

.aboutTextWrapper {
    width: 65%;
    margin: 0 auto;
}
.aboutPageWrapper p {
    font-size: 1.5em;
}

.abouttext {
    font-family: "Barlow";
    color: #484848;
}

.aboutPageWrapper p {
    font-size: 1.3em;
}

.aboutHeaders {
    font-family: "Satisfy";
    font-size: 2em;
    color: #846C6C;
}

/* For Mobile */
@media screen and (max-width: 540px) {
    .aboutPageWrapper {
        padding-top: 0.5em;
        padding-bottom: 3em;
        margin: 0%;
        width: 100%;
        float: left;
        text-align: center;
        /*background-color: pink;*/
    }

    .aboutHeaders {
        font-family: "Satisfy";
        font-size: 1.5em;
        color: #846C6C;
    }

    .aboutPageWrapper p {
        font-size: 1em;
    }
}

.orderPageWrapper {
    padding-top: 3em;
    padding-bottom: 10em;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    float: left;
    text-align: center;
    /*background-color: pink;*/
}

.orderPageWrapper h3 {
    font-size: 3em;
    font-family: "Barlow";
}

.orderPageWrapper h4 {
    font-size: 1.5em;
    padding-left: 1em;
    text-align: left;
    font-family: "Barlow";
}

.orderPageWrapper p {
    font-size: 1em;
    text-align: left;
    padding-left: 3em;
    font-family: "Barlow";
}

.orderoption {
    margin-left: 2em;
    background-color: #ffac81;
    background-image: linear-gradient(315deg, #ffac81 0%, #ff928b 74%); 
    padding-bottom: 1em;   
}
.orderoption p {
    /* padding: 0.5em; */
    padding-left: 3em;
}

.noteoption {
    margin-left: 2em;
    background-color: #eec0c6;
    background-image: linear-gradient(315deg, #eec0c6 0%, #e58c8a 74%);
    padding: 0.5em;
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .orderPageWrapper {
        padding-top: 1.5em;
        padding-bottom: 10em;
        margin: 0 5%;
        width: 90%;
        float: left;
        text-align: center;
    }
    .orderPageWrapper h3 {
        font-size: 1.5em;
        font-family: "Barlow";
    }
    .orderPageWrapper p {
        font-size: 0.9em;
        text-align: left;
        font-family: "Barlow";
    }
    
}
.contact-form, label {
    margin-top: 0.5em;
    font-size: 1em;
    display: inline-block;
    font-family: "Barlow";
}
.contact-form, h1 {
    font-size: 1em;
    font-family: "Barlow";
    display: inline-block;
}

.contact-form, input {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;
}

.contact-form, select {
    width: 50%;
    padding: 12px 0px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1em;

}

option {
    font-size: 3em;
}

.contact-form, button {
    width: 100%;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

#candleOptionForm {
    /* box-sizing: border-box; */
    width: 100%;
    display: inline-block;
}

#candleOptionForm select {
    width: 75%;
    height: 3em;
    float: left;
}

#candleOptionForm .qty {
    width: 15%;
    height: 3em;
    float: left;
    margin-left: 1em;
}

.orderBox {
    box-sizing: border-box;
    border-radius: 10px;
    border: 2px solid #846C6C;
    background-color: #D8CBCB;
    min-height: 5em;
}

.addButton {
    width: 2em;
    height: 2em;
}

.disabledButton {
    background-color: grey;
}

.abledButton {
    background-color: #4CAF50;
}

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .contact-form, label {
        font-size: 1.5em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .contact-form, label {
        font-size: 1em;
        display: inline-block;
    }
    .contact-form, h1 {
        font-size: 1em;
        display: inline-block;
    }
    .contact-form, label {
        font-size: 1em;
    }
    #candleOptionForm select {
        width: 60%;
        float: left;
        height: 2.9em;
    }
    #candleOptionForm .qty {
        width: 27%;
        height: 2.9em;
        float: left;
        margin-left: 0.2em;
    }
    .orderBox {
        box-sizing: border-box;
        border-radius: 10px;
        border: 2px solid #846C6C;
        background-color: #D8CBCB;
        min-height: 3em;
    }    
    .addButton {
        width: 1.7em;
        height: 1.7em;
        margin-left: auto;
    }

}
.CandleDisplayWrapper {
    width: 100%;
    clear: left;
}

#candleimagegrid {
    overflow: auto;
}

.candlegridcolumn {
    float: left;
    width: 50%;
    padding: 10px;
}

.candlegridcolumn img {
    margin-top: 12px;
    width: 100%;
}

#aboutcandleproducts p {
    font-family: "Satisfy";
    font-size: 1.5em;
    color: #484848;
}

.ProductType {
    font-family: "Satisfy";
    font-size: 2em;
}

#aboutcandleproducts h5 {
    font-family: "Satisfy";
    font-size: 1.1em;
    text-align: left;
    color: #484848;
}

#aboutcandleproducts li {
    font-family: "Barlow";
    text-align: left;
    color: #585858;
}

.contwrapper {
    float: left;
    width: 50%;
    padding: 5px;
}

#jarcontwrapper {
    padding-left: 5em;
}

#aboutcandleproducts {
    overflow: auto;
    width: 100%;
}

.candleButtons {
    width: 100%;
    margin-top: 2em;
}

.candleButtons p {
    font-size: 1em;
    margin: 0 auto;
    font-family: 'Barlow';
    color: #484848;
}

.candleButtons button {
    width: 10em;
    margin: 2px;
    background-color: transparent;
}

.candleButton {
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

#candletitle {
    font-size: 2em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

.candleimages {
    width: 25%;
    float: left;
}
#selectedJarCandleImage, #selectedGlassCandleImage {
    width: 100%;
    margin: 0.5em;
}
#selectedCandleDesc {
    margin-top: 2em;
    width: 70%;
    float: right;
}

.productlabel {
    text-align: left;
    font-size: 1em;
    width: 80%;
    font-family: Barlow;
    color: #585858;
}

.candledesc {
    font-size: 1.5em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .candleButtons button {
        width: 4.9em;
        margin: 1px;
    }

    .candleButtons {
        width: 100%;
        margin-top: 0.5em;
    }

    .candleButtons p {
        font-size: 0.7em;
    }

    .candleButton {
        width: 3em;
        height: 3em;
        border-radius: 50%;
    }
    #candletitle {
        font-size: 1em;
        text-align: left;
    }
    .candleimages {
        width: 30%;
    }
    #selectedJarCandleImage, #selectedGlassCandleImage {
        margin: 0.2em;
    }
    #selectedCandleDesc {
        margin-top: 0;
        width: 65%;
    }
    .productlabel {
        font-size: 0.7em;
        width: 80%;
    }
    .candledesc {
        font-size: 1em;
    }
    
}
.LipbalmDisplayWrapper {
    padding-top: 1em;
    clear: left;
    width: 100%;
}

.ProductType {
    font-family: "Satisfy";
    font-size: 2em;
}

.LipbalmButtons {
    width: 100%;
    margin-top: 2em;
}

#lipbalmbanner {
    width: 100%;
}

#aboutlips p {
    font-family: "Satisfy";
    font-size: 1.5em;
    color: #484848;
}

.LipbalmButtons p {
    font-size: 1em;
    margin: 0 auto;
    font-family: 'Barlow';
    color: #484848;
}

.LipbalmButtons button {
    width: 10em;
    margin: 2px;
    background-color: transparent;
}

.lipBalmButton {
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

#lipname {
    font-size: 2em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

.Lipbalmimages {
    width: 25%;
    float: left;
}
#selectedLipbalmImage {
    width: 100%;
    margin: 0.5em;
}
#selectedLipbalmDesc {
    margin-top: 2em;
    width: 70%;
    float: right;
}

.productlabel {
    text-align: left;
    font-size: 1em;
    width: 80%;
    font-family: Barlow;
    color: #585858;
}

.lipapplication, .lipingredients {
    font-size: 1.5em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .LipbalmButtons button {
        width: 4.9em;
        margin: 1px;
    }

    #aboutlips p {
        font-size: 1em;
    }    

    .LipbalmButtons {
        width: 100%;
        margin-top: 0.5em;
    }

    .LipbalmButtons p {
        font-size: 0.7em;
    }

    .lipBalmButton {
        width: 3em;
        height: 3em;
        border-radius: 50%;
    }
    #lipname {
        font-size: 1em;
        text-align: left;
    }

    .Lipbalmimages {
        width: 30%;
    }
    #selectedLipbalmImage{
        margin: 0.2em;
    }
    #selectedLipbalmDesc {
        margin-top: 0;
        width: 65%;
    }
    .productlabel {
        font-size: 0.7em;
        width: 80%;
    }
    .lipapplication, .lipingredients {
        font-size: 1em;
    }
    
}
.productsPageWrapper {
    padding-top: 2em;
    padding-bottom: 10em;
    float: left;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    text-align: center;
    /*background-color: pink;*/
}

.productPageHeader {
    /*background-color: rgba(162, 119, 123, 0.5);*/
    height: 10em;
    width: 100%;
    margin-bottom: 1em;
}

.productlinkbuttons {
    background-color: rgba(255, 180, 71, 0.2);
    color: black;
    font-size: 1em;
    font-family: Barlow;
}

.productLink {
    font-family: Barlow;
    font-size: 1.5em;
}

.productLinks {
    padding-bottom: 1em;
    text-align: left;
}

.productPageHeader h1{
    font-family: "Satisfy";
    font-size: 3em;
    padding-top: 0.6em;
    color: #846C6C;
}


/*
.productsCardWrapper {
    width: 85%;
    margin: 0 auto;
}*/

/*
.oddProductCard, .evenProductCard {
    padding: 0em 1em;
}*/

/*
.oddProductCard {
    overflow: auto;
    clear: left;
    clear: right;
    margin-top: 1.5em;
}

.oddProductCard .oddProductText {
    float: left;
    text-align: left;
}

.oddProductCard .oddImageCont {
    background-color: blue;
    width: 2em;
    height: 17em;
    float: right;
}

.oddProductCard img {
    float: right;
}

.evenProductCard {
    overflow: auto;
    clear: left;
    clear: right;
    margin-top: 1.5em;
}

.evenProductCard .evenProductText {
    float: right;
    text-align: right;
}

.evenProductCard .evenImageCont {
    background-color: red;
    width: 2em;
    height: 17em;
    float: left;
}

.evenProductCard img {
    float: left;
}

.productsPageWrapper img {
    width: 55%;
    height: 17em;
}*/

  /* For Mobile */
@media screen and (max-width: 540px) {
    .productsPageWrapper {
        padding-top: 0.5em;
        width: 100%;
        margin-left: 0%;
    }

    .productlinkbuttons {
        font-size: 0.7em;
    }

    .productPageHeader {
        height: 5em;
        width: 100%;
        margin-bottom: 2em;
    }

    .productPageHeader h1{
        font-family: "Satisfy";
        font-size: 1.5em;
        padding-top: 1em;
    }

    .candlegridcolumn {
        float: left;
        width: 50%;
        padding: 3px;
    }
    
    .contwrapper {
        float: left;
        width: 50%;
        padding: 5px;
    }
    
    #jarcontwrapper {
        padding-left: 1em;
    }

    #aboutcandleproducts p {
        font-family: "Satisfy";
        font-size: 1em;
    }
    
    #aboutcandleproducts h5 {

    }
    
    #aboutcandleproducts li {
        font-size: 0.8em;
    }

    /*
    .oddProductCard .oddImageCont {
        background-color: blue;
        width: 1em;
        height: 9em;
    }
    
    .evenProductCard .evenImageCont {
        background-color: red;
        width: 1em;
        height: 9em;
    }

    .productsPageWrapper img {
        width: 55%;
        height: 9em;
    }*/
}
.sliderWrapper {
    background-color: #E9D0B6;
    position: relative;
    width: 100%;
    height: 350px;
}

.sliderWrapper img {
    width: 70%;
    height: 350px;
    position: relative;
}

.sliderImages .downArrow{
    position: absolute;
    left: 50%;
    bottom: 5%;
}

.image-leave {
    -webkit-transform: width 100ms ease-in-out;
            transform: width 100ms ease-in-out;
}
.pictureMarketingWrapper {
  margin-top: 2em;
  /*width: 90%;*/
  justify-content: center;
  height: 25em;
  background-color: #fdf496;
  /*background-image: url('../../pictures/assets/amaZen_med_copy.png')*/
}
.typewriter {
    padding: 5.5em 1em;
    justify-content: center;
    width: 100%;
}
.typewriter h1 {
    font-family: 'Fredoka One';
    width: 100%;
    font-size: 1.9em;
    white-space: nowrap;
    overflow: hidden;
    letter-spacing: .25em;
    text-align: center;
}
.typewriter h1:nth-child(1){
    border-right: .15em solid orange;
    -webkit-animation: type 3s steps(40, end);
    animation: type 3s steps(40, end);
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }

  .typewriter h1:nth-child(2){
    border-right: .15em solid orange;
    opacity: 0;
    -webkit-animation: type2 3s steps(40, end);
    animation: type2 3s steps(40, end);
    -webkit-animation-delay: 2s;
    animation-delay: 3s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  
  .typewriter h1:nth-child(3){
    border-right: .15em solid orange;
    opacity: 0;
    -webkit-animation: type3 3s steps(40, end), blink .5s step-end infinite alternate;
    animation: type3 3s steps(40, end), blink .5s step-end infinite alternate;
    -webkit-animation-delay: 4s;
    animation-delay: 6s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  
  }
  
  
  /* DEMO-SPECIFIC STYLES */

  @keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @-webkit-keyframes type {
    0% {
      width: 0;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      border: none;
    }
  }
  
  @keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  @-webkit-keyframes type2 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    99.9% {
      border-right: .15em solid orange;
    }
    100% {
      opacity: 1;
      border: none;
    }
  }
  
  
  @keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes type3 {
    0% {
      width: 0;
    }
    1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes blink {
    50% {
      border-color: transparent;
    }
  }
  @-webkit-keyframes blink {
    50% {
      border-color: tranparent;
    }
  }

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .pictureMarketingWrapper {
      height: 20em;
    }
    .typewriter {
        padding: 5em 1em;
    }
    .typewriter h1 {
        font-size: 1.2em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .pictureMarketingWrapper {
        margin-top: 2em;
        padding-top: 3em;
        width: 100%;
        height: 20em;
    }
    .typewriter {
        padding: 4em 0.5em;
    }
    .typewriter h1 {
        font-size: 0.75em;
    }
}

.homePageWrapper {
    width: 75%;
    padding-top: 3em;
    padding-bottom: 9em;
    margin-left: 12%;
    margin-right: auto;
    float: left;
    text-align: center;
    /*background-color: pink;*/
}

#bannerphoto1 {
    width: 100%;
}

#bannermessage1 {
    background-color: #990000;
    padding: 0.3em;
    margin: 0.5em;
}

#bannermessage1 > h3 {
    color: white;
    font-size: 3em;
}

#bannermessage1 > h5 {
    color: white;
    font-size: 2em;
}

#bannermessage1 > h5 > a {
    color: white;
    font-size: 0.8em;
}

#bannerphoto2 {
    width: 100%;
}

#gardencaption, #collagecaption {
    text-align: right;
    font-family: Satisfy;
    font-size: 2em;
    color: #484848;
}

#lipbalmcaption {
    text-align: left;
    font-family: Satisfy;
    font-size: 2em;
    color: #484848;
}

#collagehomepage {
  width: 100%;
  margin-top: 2em;
}

#firstcollageportrait {
    float: left;
    width: 45%;
    padding-left: 3em;
}

#secondcollageportrait {
    float: left;
    width: 50.5%;
    padding-left: 4em;
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .homePageWrapper {
        padding-top: 0.5em;
        padding-bottom: 7em;
        margin: 0% 3%;
        width: 90%;
        text-align: center;
    }

    #bannerphoto1 {
        margin-top: 1em;
    }

    #gardencaption {
        font-size: 1em;
    }
    
    #collagecaption {
        font-size: 1em;
    }

    #lipbalmcaption {
        font-size: 1em;
    }

    #collagehomepage {
        margin-top: 1em;
        width: 100%;
    }

    #firstcollageportrait {
        float: left;
        width: 45%;
        padding-left: 0em;
        margin-left: 0.5em;
    }
    
    #secondcollageportrait {
        float: left;
        width: 51.5%;
        padding-left: 0em;
    }
}
.navBarWrapper {
    width: 80%;
    /*clear: left;*/
    float: right;
}

.links {
    float: left;
    font-size: 1.2em;
    text-decoration: none;
    color: #484848;
    font-family: "Barlow";
}

.link1 {
    margin-left: 9%;
}

.links:hover {
    color:  #846C6C;
    font-size: 1.1em;
}
  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .navBarWrapper {
        width: 70%;
        /*background-color: green;*/
    }

    .links {
        font-size: 1em;
    }

    .link1 {
        margin-left: 1em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .navBarWrapper {
        padding-top: 0.5em;
        width: 100%;
    }
    .links {
        margin-left: 1em;
        font-size: 0.9em;
    }
    .links:hover {
        font-size: 1em;
    }
}

.HeaderWrap {
    display: inline-block;
    width: 100%;
}
.HeaderWrap button{
    margin: 0 0;
    padding: 0 0;
    width: 15em;
    background-color: transparent;
}

.horizontalLogo {
    width: 13em;
    float: left;
}

  /* For Tablets */
@media screen and (min-width: 540px) and (max-width: 780px) {
    .horizontalLogo {
        width: 10em;
    }
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .HeaderWrap button{
        margin: 0 0;
        padding: 0 0;
        width: 10em;
    }
    .horizontalLogo {
        width: 7.5em;
    }
}
.footerWrapper {
    clear: left;
    border-top: 3px solid grey;
    margin: 0 auto;
    width: 100%;
}

.contentFooter {
    display: inline-block;
}

.footerLine {
    padding-left: 1em;
    padding-top: 0.5em;
}

.contentFooter a {
    padding-left: 0.5em;
    font-size: 0.8em;
    font-family: 'Barlow';
}

  /* For Mobile */
@media screen and (max-width: 540px) {
    .footerLine {
        padding-left: 1em;
        padding-top: 0.2em;
    }
    .contentFooter a {
        font-size: 0.85em;
    }
}
.aboutsitewrapper {
    padding-top: 3em;
    padding-bottom: 10em;
    margin: auto 5%;
    width: 80%;
    float: left;
    text-align: left;
    /*background-color: pink;*/
}

.aboutsitewrapper * {
    font-family: 'Itim'
}
/* GLOBAL STYLES ONLY, ALL OTHER CSS GOES WITH ITS COMPONENTS */
* {
  font-family: 'Pompiere';
  box-sizing: border-box;
}

.App {
  /*background-color: brown;*/
}


