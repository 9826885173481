.sliderWrapper {
    background-color: #E9D0B6;
    position: relative;
    width: 100%;
    height: 350px;
}

.sliderWrapper img {
    width: 70%;
    height: 350px;
    position: relative;
}

.sliderImages .downArrow{
    position: absolute;
    left: 50%;
    bottom: 5%;
}

.image-leave {
    transform: width 100ms ease-in-out;
}