@import url('https://fonts.googleapis.com/css?family=Barlow');

.LipbalmDisplayWrapper {
    padding-top: 1em;
    clear: left;
    width: 100%;
}

.ProductType {
    font-family: "Satisfy";
    font-size: 2em;
}

.LipbalmButtons {
    width: 100%;
    margin-top: 2em;
}

#lipbalmbanner {
    width: 100%;
}

#aboutlips p {
    font-family: "Satisfy";
    font-size: 1.5em;
    color: #484848;
}

.LipbalmButtons p {
    font-size: 1em;
    margin: 0 auto;
    font-family: 'Barlow';
    color: #484848;
}

.LipbalmButtons button {
    width: 10em;
    margin: 2px;
    background-color: transparent;
}

.lipBalmButton {
    width: 5em;
    height: 5em;
    border-radius: 50%;
}

#lipname {
    font-size: 2em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

.Lipbalmimages {
    width: 25%;
    float: left;
}
#selectedLipbalmImage {
    width: 100%;
    margin: 0.5em;
}
#selectedLipbalmDesc {
    margin-top: 2em;
    width: 70%;
    float: right;
}

.productlabel {
    text-align: left;
    font-size: 1em;
    width: 80%;
    font-family: Barlow;
    color: #585858;
}

.lipapplication, .lipingredients {
    font-size: 1.5em;
    font-family: Barlow;
    color: #484848;
    text-align: left;
}

  /* For Mobile */
  @media screen and (max-width: 540px) {
    .LipbalmButtons button {
        width: 4.9em;
        margin: 1px;
    }

    #aboutlips p {
        font-size: 1em;
    }    

    .LipbalmButtons {
        width: 100%;
        margin-top: 0.5em;
    }

    .LipbalmButtons p {
        font-size: 0.7em;
    }

    .lipBalmButton {
        width: 3em;
        height: 3em;
        border-radius: 50%;
    }
    #lipname {
        font-size: 1em;
        text-align: left;
    }

    .Lipbalmimages {
        width: 30%;
    }
    #selectedLipbalmImage{
        margin: 0.2em;
    }
    #selectedLipbalmDesc {
        margin-top: 0;
        width: 65%;
    }
    .productlabel {
        font-size: 0.7em;
        width: 80%;
    }
    .lipapplication, .lipingredients {
        font-size: 1em;
    }
    
}